import axios from "axios";
import { Toast } from 'vant';


axios.defaults.baseURL = 'https://testapi.china-wdf.org.cn/'  //正式 https://testapi.china-wdf.org.cn/
// axios.defaults.baseURL = "/api/"; 

//post请求头
axios.defaults.headers.post["Content-Type"] = "application/json;charset=UTF-8";
//设置超时
axios.defaults.timeout = 20000;

//设置请求前拦截器
// axios.interceptors.request.use(
//   config => {
    
//   }
// )

//设置请求结束后拦截器
axios.interceptors.response.use(
  response => {
    if (response.status == 200) {
      return Promise.resolve(response);
    } else {
      return Promise.reject(response);
    }
  },
  error => {
    const statusCode = error.response.status;
    if (statusCode === 401 && error.response.data.code === 10001) {
      Toast('没有操作权限')
      return
    }
    else if (statusCode === 403 || statusCode === 401) {
      Toast('登录过期,请重新登录账户')
      return
    }
    else if (statusCode === 405) {
      Toast('请求与接口要求不一致，请检查重试')
      return
    }
    else if (statusCode === 422) {
      Toast(error.response.data.message[0][0])
      return
    }
    else {
      Toast(typeof (error.response.data) != "undefined" ? error.response.data.message : error.response)
      return
    }
  }
)




// axios.interceptor.request = (config) => {
//   let token = uni.getStorageSync("dtcms_client_token");
//   //显示加载动画
//   if (params.loading) {
//     uni.showLoading({
//       title:'加载中'
//     })
//   }
//   if (token.accessToken) {
//     config.header.Authorization = 'Bearer ' + token.accessToken
//   }
// }
//设置请求结束后拦截器
// axios.interceptor.response = async (response) => {
//   //if (params.loading) uni.hideLoading(); //关闭加载效果
//   const statusCode = response.statusCode;
//   //401:accessToken过期需要刷新

// }




export function get(url, data) {
  return new Promise((resolve, reject) => {
    axios({
      method: "get",
      url,
      params: data,
    })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
}
export function post(url, data) {
  return new Promise((resolve, reject) => {
    axios({
      method: "post",
      url,
      data: data,
    })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
}
